import React, { Component } from "react"

import Layout from "../components/layout"
import { FooterAlternate1 } from "../components/footer"
import SEO from "../components/seo"

class IndexPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            buttonVisibility: 'visible',
            responseMessage: '',
            signUp: {
                fullName: '',
                email: '',
                phone: '',
                message: ''                
            }
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        var state = Object.assign(this.state, {
            signUp: Object.assign(this.state.signUp, { [event.target.id]: event.target.value }),
        });
        
        this.setState(state);

        console.log(event.target.value);
    }

    handleSubmit(event) {
        this.setState({buttonVisibility: 'invisible'});
        console.log('State Data: ' + JSON.stringify(this.state));
        event.preventDefault();

        var apigClient = window.apigClientFactory.newClient();
        var body = {
            fullName: this.state.signUp.fullName,
            email: this.state.signUp.email,
            phone: this.state.signUp.phone,
            message: this.state.signUp.message
        };
        
        apigClient.contactPost({}, body, {})
            .then(function(result){
                return result;
            })
            .then(result => {
                if (result.data !== "Complete") {
                    this.setState({
                        responseMessage: 'An error occurred while submitting your enquiry'
                    })
                } else {
                    this.setState({
                        responseMessage: 'Thank you for submitting your enquiry!'
                    })
                }
                console.log(result);
            })
            .catch( function(result){
                console.log(result);
            });
    }

    render() {
        return (
            <Layout>
                <SEO title="Enquiries" />
                
                <div className="article">
                    <header className="header header--basic article__header ">
                        <div className="header__content">
                            <div className="container">
                                <div className="row">
                                    <div className="header__inner col-md-9 col-lg-7 col-lg-offset-1">
                                        <h1 className="header__title">Enquiries</h1>
                                        <h3 className="header__caption">Send us a short description of your needs and a member of the Tribe will respond promptly.</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    <div className="container">
                        <div className="row">
                            <div className="article__body col-sm-12 col-md-8 col-lg-offset-1 col-lg-7">
                                <form onSubmit={this.handleSubmit}>
                                    <div className="form-group">
                                        <label htmlFor="fullName">Full name <span>(required)</span></label>
                                        <input className="form-control" id="fullName" maxlength="200" name="fullName" placeholder="Full Name" type="text" required onChange={this.handleChange} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="email">Email <span>(required)</span></label>
                                        <input className="form-control" id="email" maxlength="254" name="email" placeholder="Email" type="email" required onChange={this.handleChange} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="phone">Contact phone <span>(required)</span></label>
                                        <input className="form-control" id="phone" maxlength="20" name="phone" placeholder="Contact Phone" type="text" required onChange={this.handleChange} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="message">Message</label>
                                        <textarea className="form-control" cols="40" id="message" name="message" rows="10" onChange={this.handleChange}>
                                        </textarea>
                                    </div>
                                    <button type="submit" className={`btn btn-default ${this.state.buttonVisibility}`}>Submit</button>
                                    <h3>{this.state.responseMessage}</h3>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                
                <FooterAlternate1 />

            </Layout>
        )
    }
}

export default IndexPage